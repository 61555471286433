<template>
    <Dialog v-model:visible="this.$store.state.didNumber.updateDIDNumberPopUp" :style="{ width: '900px' }"
        header="Update DID Number" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-11">
                    <label for="status">
                        Status
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="status" v-model="edit.status" :options="statusList" optionLabel="label"
                        placeholder="Select Status" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.status.$error">{{ v$.edit.status.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label>Number Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="normal_number" name="option" v-bind:value="1" v-model="edit.numbertype"
                            :checked="edit.numbertype == 1" />
                        <label for="normal_number" class="p-mr-2">Normal Number</label>
                        <RadioButton id="callback_number" name="option" v-bind:value="2" v-model="edit.numbertype"
                            :checked="edit.numbertype == 2" />
                        <label for="callback_number" class="p-mr-2">Callback Number</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateDIDNumber()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { inject } from 'vue';
export default {
    inject: [global],
    data() {
        const globalData = inject('global');
        return {
            v$: useValidate(),
            showLoader: false,
            submitted: false,
            updateDIDNumberDialogStatus: false,
            statusList: globalData.state.statusList,
            edit: {
                v$: useValidate(),
                numbertype: '',
                defaultnumberstatus: '',
                status: '',
                row_id: '',
            },
        }
    },
    ApiService: null,
    //to get passed data for edit
    computed: {
        toggleUpdateDIDNumberPopUp() {
            return this.$store.state.didNumber.updateDIDNumberPopUpData;
        },
    },
    watch: {
        toggleUpdateDIDNumberPopUp() {
            if (this.$store.state.didNumber.updateDIDNumberPopUp) {
                this.edit.numbertype = this.$store.state.didNumber.updateDIDNumberPopUpData.numbertype;
                this.edit.defaultnumberstatus = this.$store.state.didNumber.updateDIDNumberPopUpData.defaultnumberstatus;
                this.edit.status = this.$store.state.didNumber.updateDIDNumberPopUpData.status;
                this.edit.row_id = this.$store.state.didNumber.updateDIDNumberPopUpData.row_id;
            }
        },
    },
    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            edit: {
                status: { required: helpers.withMessage('Please Select Status', required) },
            }
        };
    },
    methods: {
        reloadnewDIDnumberList() {
            this.$store.dispatch("toggleUpdateDIDNumberPopUp");
        },

        updateDIDNumber() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['flag'] = 1;
            fields['cn1'] = this.edit.row_id;
            fields['cn13'] = this.edit.numbertype;
            fields['cn16'] = this.edit.status.value;
            fields['cn24'] = this.edit.defaultnumberstatus;
            this.v$.edit.$validate();
            if (!this.v$.edit.$error) {
                this.showLoader = true;
                this.ApiService.updateDIDNumber(fields).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.updateDIDNumberDialogStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);

                        this.edit.row_id = 0;
                        this.edit.numbertype = 0;
                        this.edit.status = '';
                        this.edit.numbertype = 0;
                        this.edit.defaultnumberstatus = 0;
                        this.reloadnewDIDnumberList();
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
    }
}

</script>