<template>
    <headerComponent />
    <Toolbar>
        <template v-slot:right>
            <Button label="Add DID Number" icon="pi pi-plus" class=" p-mr-2" @click="addDIDNumberDialogOpen()" />
            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="filterlisting()"></Button>
            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                @click="filterlisting()"></Button>
        </template>
    </Toolbar>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="didnumberlist" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="bd1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <!-- <Toolbar>
                    <template v-slot:right>
                        <Button label="Add DID Number" icon="pi pi-plus" class=" p-mr-2" @click="addDIDNumberDialogOpen()" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="filterlisting()"></Button>
                        <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success" @click="filterlisting()"></Button>
                    </template>
                </Toolbar> -->
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 10%">
                        <template #body="{ index }">
                            <div>
                                <div>{{ ++index }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="DID Number" headerStyle="width: 27%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn2 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Forwarded No" headerStyle="width: 19%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn7 ? data.cn7 : '-' }}</div>
                            </div>
                        </template>
                    </Column>
                    <!-- <Column header="Forwarded No 2" headerStyle="width: 20%">
                    <template #body="{ data }">
                        <div>
                            <div>{{ data.cn10 }}</div>
                        </div>
                    </template>
                </Column> -->
                    <Column header="Admin" headerStyle="width: 18%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn15 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Telecom Operator" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn4 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Service Provider" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn6 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editDIDNumberDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add DID number dialog start here -->
    <didNumberPopUpComponent />
    <!-- add DID number dialog end here -->
    <!-- edit DID Number start here -->
    <updateDIDNumberPopUp />
    <!-- <Dialog v-model:visible="updateDIDNumberDialogStatus" :style="{ width: '900px' }" header="Update DID Number"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-11">
                    <label for="status">
                        Status
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="status" v-model="edit.status" :options="statusList" optionLabel="label"
                        placeholder="Select Status" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.status.$error">{{ v$.edit.status.required.$message
                        }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateDIDNumber()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog> -->
    <!-- edit DID Number dialog end here -->

    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="admin">Admin</label>
                    <MultiSelect v-model="admin_fks" :options="adminList" optionValue="value" optionLabel="label"
                        placeholder="Select admin" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="operator">
                        Operator
                    </label>
                    <!-- <Dropdown id="operator" v-model="operator"
                        :options="operatorList" optionLabel="label" placeholder="Select Operator" :filter="true"
                        appendTo="body"></Dropdown> -->
                    <MultiSelect v-model="operator_fks" :options="operatorList" optionValue="value" optionLabel="label"
                        placeholder="Select Operator" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="didnumber">
                        DID Number
                    </label>
                    <InputText id="didnumber" class="p-text-capitalize" v-model.trim="add.didnumber" required="true"
                        maxlength="50" />
                </div>
                <div class="p-field p-col-12">
                    <label for="state">
                        State
                    </label>
                    <!-- <Dropdown id="state" v-model="state" 
                        :options="statesList" optionLabel="label" placeholder="Select State" :filter="true" @change="selectclientcity($event)"
                        appendTo="body"></Dropdown> -->
                    <MultiSelect v-model="state_fks" :options="statesList" optionValue="value" optionLabel="label"
                        placeholder="Select State" :filter="true" class="multiselect-custom" appendTo="body"
                        @change="selectclientcity($event)"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="city">
                        City
                    </label>
                    <!-- <Dropdown id="city" v-model="city" 
                        :options="citiesList" optionLabel="label" placeholder="Select City" :filter="true"
                        appendTo="body"></Dropdown> -->
                    <MultiSelect v-model="city_fks" :options="citiesList" optionValue="value" optionLabel="label"
                        placeholder="Select City" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label>Number Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="normal_number" name="option" v-bind:value="1" v-model="add.numbertype"
                            :checked="add.numbertype == 1" />
                        <label for="normal_number" class="p-mr-2">Normal Number</label>
                        <RadioButton id="callback_number" name="option" v-bind:value="2" v-model="add.numbertype"
                            :checked="add.numbertype == 2" />
                        <label for="callback_number" class="p-mr-2">Callback Number</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(admin_fks, operator_fks, add.didnumber, state_fks, city_fks, numbertype)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import headerComponent from '../common/commonHeaderOfDIDNumber.vue'
import didNumberPopUpComponent from '../cloudtelephony/addNewDIDNumberPopUp.vue'
import updateDIDNumberPopUp from '../cloudtelephony/updateDIDNumberPopUp.vue'
import { inject } from 'vue';
export default {
    inject: [global],
    components: { headerComponent, didNumberPopUpComponent, updateDIDNumberPopUp },
    data() {
        const globalData = inject('global');
        return {
            v$: useValidate(),
            page_no: 0,
            totalRecords: 0,
            loading: false,
            updateDIDNumberDialogStatus: false,
            filterListingDialog: false,
            row_id: null,
            didnumberlist: [],
            operatorList: globalData.state.operatorList,
            statesList: [],
            citiesList: [],
            showLoader: false,
            submitted: false,
            showFillFilter: false,
            statusList: globalData.state.statusList,
            add: {
                didnumber: '',
                operator: '',
                state: '',
                city: '',
                numbertype: '',
                defaultnumberstatus: '',
            },
            edit: {
                v$: useValidate(),
                numbertype: '',
                defaultnumberstatus: '',
                status: '',
                row_id: '',
            },
            admin: '',
            adminList: [],
            admin_fks: [],
            operator_fks: [],
            state_fks: [],
            city_fks: [],
            showloaderpage: false,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getDIDNumberByStatus({ page_no: this.page_no, cn16: 1 });
        this.getStates();
        this.getAdmin();
    },
    computed: {
        didNumberCallApiStatus() {
            return this.$store.state.didNumber.didNumberCallApiStatus;
        },
    },

    watch: {
        didNumberCallApiStatus() {
            this.getDIDNumberByStatus({ cn16: 1 });
        },
    },
    methods: {
        getDIDNumberByStatus(ev) {
            this.loading = true;
            this.ApiService.getDIDNumberByStatus(ev).then((data) => {
                if (data.status == 200) {
                    this.didnumberlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.didnumberlist = [];
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getDIDNumberByStatus({ page_no: this.page_no, cn16: 1 });
        },

        addDIDNumberDialogOpen() {
            this.$store.dispatch("toggleAddNewDIDNumberPopUp");
        },

        editDIDNumberDialogOpen(e) {
            this.submitted = false;
            this.updateDIDNumberDialogStatus = true;
            this.edit.defaultnumberstatus = e.cn24;
            this.edit.numbertype = e.cn13;
            this.edit.row_id = e.cn1;

            let cn16 = this.statusList.filter(function (item) {
                return item.value == e.cn16;
            });
            if (cn16.length > 0) {
                this.edit.status = cn16[0];
            }

            this.$store.dispatch("toggleUpdateDIDNumberPopUp", this.edit)
        },

        reloadnewDIDnumberList() {
            this.loading = true;
            this.ApiService.getDIDNumberByStatus({ cn16: 1 }).then((data) => {
                if (data.status == 200) {
                    this.didnumberlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.didnumberlist = '';
                }
                this.loading = false;
            });
        },

        filterlisting() {
            this.filterListingDialog = true;
        },

        getFilteredData(admin, operator, didnumber, state, city, numbertype) {
            this.loading = true;
            this.admin_fks = admin;
            this.operator_fks = operator;
            this.state_fks = state;
            this.didnumber = didnumber;
            this.city_fks = city;
            this.numbertype = numbertype;
            this.getDIDNumberByStatus({
                page_no: this.page_no,
                cn16: 1,
                cn14: this.admin_fks,
                cn3: this.operator_fks,
                cn20: this.state_fks,
                cn2: this.didnumber,
                cn21: this.city_fks,
                cn13: this.numbertype,
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },

        reSetFilteredData() {
            this.admin_fks = "";
            this.operator_fks = "";
            this.state_fks = "";
            this.didnumber = "";
            this.city_fks = "";
            this.numbertype = "";
            this.reloadnewDIDnumberList();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },

        getStates(ev) {
            this.ApiService.getStates(ev).then((items) => {
                if (items.success === true) {
                    this.statesList = items.data;
                } else {
                    this.statesList = [];
                }
            });
        },

        selectclientcity(ev) {
            this.getCity(ev);
        },

        getCity(e) {
            if (e.value) {
                this.ApiService.getCities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.citiesList = items.data;
                    } else {
                        this.citiesList = [];
                    }
                });
            }
        },

        getAdmin(ev) {
            this.ApiService.getAdminListOnly(ev).then((items) => {
                if (items.success === true) {
                    this.adminList = items.data.records;
                } else {
                    this.adminList = '';
                }
            });
        }

    },
};
</script>

<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>

<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}
</style>