<template>
    <Dialog v-model:visible="this.$store.state.didNumber.addNewDIDNumberPopUp" :style="{ width: '900px' }"
        header="Add DID Number" :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-5">
                    <label for="didnumber">
                        DID Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="didnumber" class="p-text-capitalize" v-model.trim="add.didnumber" required="true"
                        maxlength="50" autofocus :class="{ 'p-invalid': submitted && !add.didnumber }" />
                    <small class="p-invalid p-error" v-if="v$.add.didnumber.$error">{{
                        v$.add.didnumber.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label for="operator">
                        Operator
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="operator" v-model="add.operator" :options="operatorList" optionLabel="label"
                        placeholder="Select Operator" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.operator.$error">{{ v$.add.operator.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="add.state" :options="statesList" optionLabel="label"
                        placeholder="Select State" :filter="true" @change="selectclientcity($event)" appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.state.$error">{{ v$.add.state.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city" v-model="add.city" :options="citiesList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.city.$error">{{ v$.add.city.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label>Number Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="normal_number" name="option" v-bind:value="1" v-model="add.numbertype"
                            :checked="add.numbertype == 1" />
                        <label for="normal_number" class="p-mr-2">Normal Number</label>
                        <RadioButton id="callback_number" name="option" v-bind:value="2" v-model="add.numbertype"
                            :checked="add.numbertype == 2" />
                        <label for="callback_number" class="p-mr-2">Callback Number</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-5" v-if="add.numbertype == 2">
                    <label>Defualt Number</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="defualt_number_yes" name="option" v-bind:value="1"
                            v-model="add.defaultnumberstatus" :checked="add.defaultnumberstatus == 1" />
                        <label for="defualt_number_yes" class="p-mr-2">Yes</label>
                        <RadioButton id="defualt_number_no" name="option" v-bind:value="0"
                            v-model="add.defaultnumberstatus" :checked="add.defaultnumberstatus == 0" />
                        <label for="defualt_number_no" class="p-mr-2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addNewDIDNumber()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, minLength, maxLength, numeric } from '@vuelidate/validators';
import { inject } from 'vue';
export default {
    inject: ['global'],
    data() {
        const globalData = inject('global');
        return {
            v$: useValidate(),
            operatorList: globalData.state.operatorList,
            statesList: [],
            citiesList: [],
            showLoader: false,
            submitted: false,
            statusList: globalData.state.statusList,
            add: {
                v$: useValidate(),
                didnumber: '',
                operator: '',
                state: '',
                city: '',
                numbertype: '',
                defaultnumberstatus: '',
            },
            admin: '',
            adminList: [],
            admin_fks: [],
            operator_fks: [],
            state_fks: [],
            city_fks: [],
            showloaderpage: false,
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getStates();
        this.getAdmin();
    },
    validations() {
        return {
            add: {
                didnumber: { required: helpers.withMessage('Please Enter DID Number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(12) },
                operator: { required: helpers.withMessage('Please Select Operator', required) },
                state: { required: helpers.withMessage('Please Select State', required) },
                city: { required: helpers.withMessage('Please Select City', required) },
            },
        };
    },
    methods: {
        reloadnewDIDnumberList() {
            this.$store.dispatch("didNumberCallApiStatus");
            this.$store.dispatch("toggleAddNewDIDNumberPopUp");
        },

        addNewDIDNumber() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['cn2'] = this.add.didnumber;
            fields['cn3'] = this.add.operator.value;
            fields['cn4'] = this.add.operator.label;
            fields['cn20'] = this.add.state.value;
            fields['cn21'] = this.add.city.value;
            fields['cn22'] = this.add.city.label;
            fields['cn13'] = this.add.numbertype;
            fields['cn24'] = this.add.defaultnumberstatus;
            this.v$.add.$validate();
            if (!this.v$.add.$error) {
                this.showLoader = true;
                this.ApiService.addDIDNumber(fields).then((items) => {
                    if (items.status == 200) {
                        var successMsg = items.message;
                        this.addDIDNumberDialogStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.add.didnumber = '';
                        this.add.operator = '';
                        this.add.state = '';
                        this.add.city = '';
                        this.add.numbertype = 1;
                        this.add.defaultnumberstatus = 1;
                        this.reloadnewDIDnumberList();
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        getStates(ev) {
            this.ApiService.getStates(ev).then((items) => {
                if (items.success === true) {
                    this.statesList = items.data;
                } else {
                    this.statesList = [];
                }
            });
        },

        selectclientcity(ev) {
            this.getCity(ev);
        },

        getCity(e) {
            if (e.value) {
                this.ApiService.getCities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.citiesList = items.data;
                    } else {
                        this.citiesList = [];
                    }
                });
            }
        },

        getAdmin(ev) {
            this.ApiService.getAdminListOnly(ev).then((items) => {
                if (items.success === true) {
                    this.adminList = items.data;
                } else {
                    this.adminList = '';
                }
            });
        }

    },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss" scoped></style>